<template>
  <ion-page class="tabs-layout">
    <a-tabs></a-tabs>
  </ion-page>
</template>

<script>
  import ATabs from '@/plugins/app/_layout/a-tabs.vue'

  export default {
    components: {
      ATabs,
    },
  }
</script>
