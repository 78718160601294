
  import { IonTabBar, IonTabButton, IonTabs } from '@ionic/vue'
  import { isPlatform } from '@ionic/vue'

  export default {
    components: {
      IonTabBar,
      IonTabButton,
      IonTabs,
    },
    data() {
      return {
        selectedTab: 'profile',
        showLogos: true
      }
    },

    watch: {
      $route (to, from){
        const split = to.fullPath.split('/')[2]
        if (split == 'login') {
          if (this.selectedTab == 'profile')
            return
          if (this.selectedTab == 'my-collection')
            return

          this.activateTab('profile')
        }
      }
    },

    created() {
      setInterval(() => {

        const element = document.getElementById('tabBar')
        if (window.innerWidth > 767) {
          element.classList.remove('tab-bar-hidden')
        }
        if (element.offsetHeight < 600) {
          return this.showLogos = false
        }

        this.showLogos = true
      }, 100)
    },

    computed: {
      isTablet() {
        return isPlatform('ipad')
      }
    },
    methods: {
      activateTab(tab) {
        this.selectedTab = tab
      },

      afterTabChange(e) {
        if (this.selectedTab != e.tab) {
          this.selectedTab = e.tab
        }
      },

      openSponsoreLink(link) {
        window.open(link)
      }
    }
  }
